import { createStore } from 'vuex'
import { useToast } from 'vue-toastification'

import axios from 'axios'
import router from '../router/index'

const toast = useToast();

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

const createCors = {
  withCredentials: true,
  headers: {
    'Authorization': `Bearer ${process.env.VUE_APP_API_SECRET}`,
    'Content-Type': 'application/json',
  },
};

export default createStore({
  state: {
    decodeTokenPartnership: [],
    detailPartnership: [],
    intrnPartnership: [],
    saldoPartnership: [],
    saldoTrnPartnership: [],
  },
  getters: {
  },
  mutations: {
    decodeTokenPartnership(state, decodeTokenPartnership){
        state.decodeTokenPartnership = decodeTokenPartnership
    },
    detailPartnership(state, detailPartnership){
        state.detailPartnership = detailPartnership
    },
    intrnPartnership(state, intrnPartnership){
        state.intrnPartnership = intrnPartnership
    },
    saldoPartnership(state, saldoPartnership){
        state.saldoPartnership = saldoPartnership
    },
    saldoTrnPartnership(state, saldoTrnPartnership){
        state.saldoTrnPartnership = saldoTrnPartnership
    }
  },
  actions: {
    async loginPartnership(res, req){
      await api
         .post("/loginPartnership", {
             username: req.username,
             password: req.password
         }, createCors)
         .then(() => {
             router.push('/home');
         })
         .catch((error) => {
             let errorMessage = error.response.data;
             toast.error(errorMessage.message);
         });
    },
    async logoutPartnership(){
        await api
        .get("logoutPartnership", createCors)
        .then(() => {
            router.push('/');
        })
        .catch((error) => console.log(error));
    },
    async decodeTokenPartnership({ commit }){
        await api
            .get("/decodeTokenPartnership", createCors)
            .then((response) => {
                let decodeTokenPartnership = response.data.data;

                commit('decodeTokenPartnership', decodeTokenPartnership);
            })
    },
    async detailPartnership({commit}, req){
        await api
          .get(`/detailPartnership/${req.uuid_partnership}`, createCors)
          .then((response) => {
            let detailPartnership = response.data.detailPartnership;
  
            commit('detailPartnership', detailPartnership);
          })
    },
    async intrnPartnership({commit}, req){
        await api
            .get(`/intrnPartnership/${req.uuid_partnership}`, createCors)
            .then((response) => {
            let intrnPartnership = response.data.intrnPartnership;

            commit('intrnPartnership', intrnPartnership);
            })
    },
    async saldoPartnership({commit}, req){
        await api
          .get(`/saldoPartnership/${req.uuid_partnership}`, createCors)
          .then((response) => {
            let saldoPartnership = response.data.saldoPartnership;
  
            commit('saldoPartnership', saldoPartnership);
          })
    },
    async saldoTrnPartnership({commit}, req){
        await api
          .get(`/saldoTrnPartnership/${req.uuid_partnership}`, createCors)
          .then((response) => {
            let saldoTrnPartnership = response.data.saldoTrnPartnership;
  
            commit('saldoTrnPartnership', saldoTrnPartnership);
          })
    },
    async mintaPenarikan(res, req){
        await api
          .post("/mintaPenarikan", {
                uuid: req.uuid_partnership,
                noInvoice: req.noInvoice,
                kodeVoucher: req.kodeVoucher,
                saldoJalan: req.saldoJalan,
                jmlTarik: req.jmlTarik
          }, createCors)
          .then(() => {
                toast.success('Yey! Permintaan penarikan saldo berhasil');
                location.reload();
          })
    },
    async gantiPassword(res, req){
      await api
        .post("/gantiPassword", {
              uuid: req.uuid_partnership,
              passwordLama: req.passwordLama,
              passwordBaru: req.passwordBaru,
        }, createCors)
        .then(() => {
          toast.success('Yey! password berhasil di terapkan');

          setTimeout(function() {
            api.get("logoutPartnership", createCors)
               .then(() => {
                   location.href = '/';
               });
          }, 3000);
        }).catch(() => {
          toast.error('Gagal! password lama tidak cocok');
        });
  },
  },
  modules: {
  }
})
