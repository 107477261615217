import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'LoginView',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/home',
    name: 'HomeView',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/akun',
    name: 'AkunView',
    component: () => import('../views/AkunView.vue')
  },
  {
    path: '/riwayat-penarikan',
    name: 'RiwayatPenarikanView',
    component: () => import('../views/RiwayatPenarikanView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
