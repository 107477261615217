import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import VueSweetalert2 from 'vue-sweetalert2';
import Toast from "vue-toastification";

// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import 'bootstrap/dist/css/bootstrap.css';
import '/src/assets/css/main.css';
import 'sweetalert2/dist/sweetalert2.min.css';

createApp(App)
    .use(store)
    .use(router)
    .use(Toast)
    .use(moment)
    .use(VueSweetalert2)
.mount('#app')
